// wp-spotlight notish
.jlt-notice-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999999999999;
    background: #ff6347;
    .jlt-notice-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0px 6px;
        gap: 14px;
        p {
            font-size: 1rem;
            font-weight: 500;
            line-height: 2rem;
            color: white;
            letter-spacing: 0.3px;
        }
        a {
            color: white;
            text-decoration: none;
            border-radius: 6px;
            padding: 2px 12px;
            font-size: 0.9rem;
            font-weight: 500;
            background: #000000;
        }
    }
}

.jlt-notice-wrapper + main {
    top: 43px;
    .jlt-is-sticky {
        top: 43px !important;
    }
}
