.jlt-install-slideover {
    position: fixed;
    left: 5px;
    bottom: 5px;
    background: #fff;
    border-radius: 6px;
    z-index: 9999999999;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.1);
    transition: all 0.4s;
    .jltwp-install-slideover-wrapper {
        display: flex;
        gap: 20px;
        padding: 30px 25px;
        .jltwp-install-slideover-icon {
            img {
                width: 48px;
                height: 48px;
            }
        }
        .jltwp-install-slideover-content {
            width: 224px;
            p {
                font-size: 16px;
                line-height: 24px;
            }
            a {
                display: block;
                width: max-content;
                font-size: 15px;
                line-height: 22px;
                font-weight: 400;
                color: #fff;
                text-decoration: none;
                padding: 6px 20px;
                border-radius: 6px;
                margin-top: 18px;
                background: #2a62ff;
            }
        }
        button {
            position: absolute;
            top: 10px;
            right: 14px;
            border: 0;
            cursor: pointer;
            background: transparent;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}
