.jlt-popup {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    min-height: 100%;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.18s ease;
    z-index: 99999;

    .jlt-popup-content {
        position: relative;
        background: #ececec;
        padding: 0px;
        border: 1px solid #cccc;
        box-shadow: 0 0 3px 0px #ccc;
        .jlt-popup-close {
            position: absolute;
            top: -10px;
            right: -10px;
            background: #fff;
            border: 1px solid #ddd;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 99999;
            svg {
                path {
                    fill: #1d2939;
                }
            }
        }
    }
}
